import SupervisedUserCircleIcon from '@material-ui/icons/Videocam'
import VideoList from './VideoList'
import VideoShow from './VideoShow'
import VideoCreate from './VideoCreate'
import VideoEdit from './VideoEdit'

const Videos = {
  list: VideoList,
  show: VideoShow,
  edit: VideoEdit,
  create: VideoCreate,
  icon: SupervisedUserCircleIcon,
}


export default Videos
