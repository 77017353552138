import React from 'react'
import {
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  ImageField,
  BooleanField,
  FileField
} from 'react-admin'
import {ShowAction} from '../../components/actions'
import ChipArrayField from '../../components/chipArrayField'


const VideoShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props}/>}>
      <SimpleShowLayout>
        <ImageField source="thumbnailPath" sortable={false}/>
        <ImageField source="listThumbnailPath" sortable={false}/>
        <ImageField source="recommendThumbnailPath" sortable={false}/>
        <ChipArrayField {...props} type="categories" label="카테고리"/>
        <TextField source="name"/>
        <TextField source="subName"/>
        <RichTextField source="contents"/>
        <BooleanField source="activeStatus"/>
        <FileField source="highVideo" title="1080p 영상 보기" />
        <FileField source="middleVideo" title="720p 영상 보기" />
        <FileField source="lowVideo" title="480p 영상 보기" />\
        <TextField source="totalTime"/>
        <TextField source="viewCount"/>
        <TextField source="replayCount"/>
        <BooleanField source="isSecret"/>
        <BooleanField source="isRecommend"/>
        <ChipArrayField {...props} type="tagNames" label="태그"/>
        <ChipArrayField {...props} type="birthRanges" label="연령대"/>
        <ChipArrayField {...props} type="exercises" label="관심운동"/>
        <ChipArrayField {...props} type="muscleParts" label="관리부위"/>
        <DateField source="createdAt" locales='ko' showTime/>
      </SimpleShowLayout>
    </Show>
  )
}

export default VideoShow
