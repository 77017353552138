import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {getResources, MenuItemLink} from 'react-admin'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import TocIcon from '@material-ui/icons/Toc'
import SubMenu from './SubMenu'
import {menus} from './menus'
import { makeStyles } from '@material-ui/core/styles'

const useMenuStyles = makeStyles({
  root: {
    marginTop: '10px'
  },
  active: {
    backgroundColor: '#DFDFDF'
  }
})

const Menu = ({onMenuClick, dense}) => {
  const classes = useMenuStyles()
  const history = useHistory()
  const [state, setState] = useState({
    menus: menus.map(({items}) => ({
      selected: items && !!items.find((item) => history.location.pathname.indexOf(item) > 0)
    }))
  })
  const resources = useSelector(getResources)

  const handleToggle = (index) => {
    state.menus[index].selected = !state.menus[index].selected
    setState((state) => ({...state}))
  }
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  return menus
    .map((menu, index) => {
      let items
      if (menu.items.length > 0) {
        items = menu.items.map((item, index) => {
          const resource = resources.find((resource) => resource.name === item)
          if (!resource) return null
          if (resource) {
            if (resource.name === 'configuration') {
              return (
                <MenuItemLink
                  key={index}
                  dense={dense}
                  onClick={onMenuClick}
                  to="/configuration/edit"
                  primaryText={menu.names[index]}
                  leftIcon={resource.icon ? <resource.icon /> : <TocIcon />}
                />
              )
            }
            return (
              <MenuItemLink
                key={index}
                to={`/${resource.name}`}
                leftIcon={resource.icon ? <resource.icon /> : <TocIcon />}
                primaryText={menu.names[index]}
                dense={dense}
                onClick={onMenuClick}
              />
            )
          }
          return (
            <MenuItemLink
              disabled
              key={index}
              to="/"
              leftIcon={resource.icon ? <resource.icon /> : <TocIcon />}
              primaryText={`${item} (개발)`}
              dense={dense}
              onClick={onMenuClick}
            />
          )
        })
        return (
          <SubMenu
            key={index}
            isOpen={state.menus[index].selected}
            handleToggle={() => handleToggle(index)}
            icon={<ViewModuleIcon />}
            sidebarIsOpen={open}
            name={menu.title}
            dense={dense}
          >
            {items}
          </SubMenu>
        )
      } else {
        return menu.items.map((item, index) => {
          const resource = resources.find((resource) => resource.name === item)
          if (resource) {
            return (
              <MenuItemLink
                classes={classes}
                key={index}
                to={`/${resource.name}`}
                leftIcon={resource.icon ? <resource.icon /> : <TocIcon />}
                primaryText={menu.names[index]}
                dense={dense}
                onClick={onMenuClick}
              />
            )
          }
          return (
            <MenuItemLink
              disabled
              key={index}
              to="/"
              leftIcon={<TocIcon />}
              primaryText={`${item} (개발)`}
              dense={dense}
              onClick={onMenuClick}
            />
          )
        })
      }
    })
}

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object
}

export default Menu
