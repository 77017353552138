import {TopToolbar, ListButton, DeleteWithConfirmButton} from 'react-admin'
import React from 'react'

const DeleteActions = ({record, props, basePath}) => (
  <TopToolbar>
    <ListButton {...props} />
    <DeleteWithConfirmButton record={record} submitOnEnter={false} />
  </TopToolbar>
)

export default DeleteActions
