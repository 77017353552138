import {
  CREATE,
  GET_ONE,
  GET_LIST,
  DELETE,
  UPDATE,
  GET_MANY
} from 'react-admin'

export function request(apiUrl, type, params) {
  let url = apiUrl + '/faqs'
  let options = {}
  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case UPDATE:
      options.method = 'PUT'
      url += `/${params.id}`
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data, status} = res
  switch (type) {
    case GET_MANY:
      if (params.ids.length === 1) {
        ret = {data: [data]}
      } else ret = {data}
      break
    case GET_LIST:
      data.data = data.data.map(item => {
        item.contents = `${item.contents.replace(/(<([^>]+)>)/ig,"").substring(0, 20)}...`
        return item
      })
      if (data) ret = data
      break
    case DELETE:
      if(status === 204) ret = {data: {id: params.id}}
      break
    default:
      ret = {data}
      break
  }
  return ret
}


