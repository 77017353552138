import HelpIcon from '@material-ui/icons/Help'
import QuestionList from './QuestionList'
import QuestionShow from './QuestionShow'
import QuestionEdit from './QuestionEdit'

const Banners = {
  list: QuestionList,
  show: QuestionShow,
  edit: QuestionEdit,
  icon: HelpIcon,
}


export default Banners
