import React from 'react'
import {Toolbar, SaveButton} from 'react-admin'

const CreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton redirect="list" submitOnEnter={false} />
  </Toolbar>
)

export default CreateToolbar
