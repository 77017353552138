import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {TopToolbar, CreateButton, Datagrid, List, TextField, BooleanField, Filter, SearchInput} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" placeholder="제목으로 검색" alwaysOn/>
  </Filter>
)

const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const TagList = withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          filters={<ListFilter/>}
          sort={{ field: 'id', order: 'DESC' }}
          actions={<Actions/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <TextField source="name" sortable={false}/>
        <BooleanField source="isRecommend" sortable={false}/>
      </Datagrid>
    </List>
  )
})

export default TagList
