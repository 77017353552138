import React from 'react'
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../components/actions/CreateActions'
import CreateToolbar from '../../components/toolbars/CreateToolbar'

const TagCreate = (props) => {
  return (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm
        toolbar={<CreateToolbar/>}>
        <TextInput source="name" validate={required}/>
        <BooleanInput source="isRecommend" validate={required}/>
      </SimpleForm>
    </Create>
  )
}

export default TagCreate
