import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  DateTimeInput
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../components/actions/CreateActions'
import CreateToolbar from '../../components/toolbars/CreateToolbar'

const PushCreate = (props) => {
  return (
    <Create {...props} actions={<CreateActions/>}>
      <SimpleForm
        toolbar={<CreateToolbar/>}>
        <TextInput source="title" validate={required}/>
        <TextInput source="contents" multiline validate={required}/>
        <DateTimeInput source="reservedDate" validate={required}/>
      </SimpleForm>
    </Create>
  )
}

export default PushCreate
