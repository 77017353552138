import {GET_LIST, GET_MANY} from 'react-admin'

export function request(apiUrl, type, params) {
  let url = apiUrl + '/files'
  const options = {}

  switch (type) {
    case 'UPLOAD':
      url += '/upload'
      options.params = params.data
      break
    case GET_LIST:
      const {productId} = params.filter
      options.params = {productId}
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
