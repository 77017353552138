import React from 'react'
import {
  BooleanField, DateField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin'
import {ShowAction} from '../../components/actions'


const BannerShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props}/>}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false}/>
        <ImageField source="imagePath" sortable={false}/>
        <BooleanField source="isActive" sortable={false}/>
        <DateField source="createdAt" locales='ko' showTime sortable={false}/>
      </SimpleShowLayout>
    </Show>
  )
}

export default BannerShow
