import React from 'react'
import {
  BooleanField, DateField,
  EmailField,
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin'
import {ShowAction} from '../../components/actions'


const QuestionShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props}/>}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false}/>
        <TextField source="nickname" sortable={false}/>
        <EmailField source="email" sortable={false}/>
        <TextField source="title" sortable={false}/>
        <TextField source="contents" sortable={false}/>
        <BooleanField source="status" sortable={false}/>
        <DateField source="createdAt" locales='ko' sortable={false}/>
      </SimpleShowLayout>
    </Show>
  )
}

export default QuestionShow
