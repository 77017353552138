import {withStyles} from '@material-ui/core/styles'
import React, {useState, useEffect} from 'react'
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  DateField,
  List,
  TextField,
  ImageField,
  BooleanField,
  Filter, SearchInput,
  SelectInput, SelectArrayInput
} from 'react-admin'
import ChipArrayField from "../../components/chipArrayField"
import {dataProvider} from "../../App"
import {required} from "../../validators"

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" placeholder="제목으로 검색" alwaysOn/>
    <SelectArrayInput
      label="카테고리"
      source="categoryIds"
      alwaysOn
      style={{width: '200px'}}
      multiple={true}
      choices={
        [
          {id: 2, name: '수면'},
          {id: 3, name: '명상'},
          {id: 4, name: '집중'},
          ...props.categories.map(category => {
            return {id: category.id, name: `레슨 - ${category.name}`}})
        ]
      }
    />
  </Filter>
)

const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const VideoList = withStyles(styles)(({classes, ...props}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function getCategories() {
      try {
        const {data} = await dataProvider('GET_LIST', 'categories', {
          pagination: {page: 1, perPage: 100},
          sort: {},
          filter: {status: true}
        })
        setCategories(data)
      } catch (e) {
        console.log(e)
      }
    }
    getCategories().then()
  }, [dataProvider])
  return (
    <List {...props}
          filters={<ListFilter  categories={categories}/>}
          sort={{ field: 'id', order: 'DESC' }}
          actions={<Actions/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <ChipArrayField {...props} type="categories" label="카테고리"/>
        <ImageField source="thumbnail" sortable={false}/>
        <TextField source="name" sortable={false}/>
        <TextField source="subName" sortable={false}/>
        <TextField source="viewCount" />
        <TextField source="replayCount" />
        <TextField source="likeCount" />
        <BooleanField source="activeStatus"/>
        <BooleanField source="isSecret"/>
        <DateField source="createdAt" locales='ko' showTime sortable={false}/>
      </Datagrid>
    </List>
  )
})

export default VideoList
