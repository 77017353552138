import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  Datagrid,
  DateField,
  List,
  TextField,
  NumberField,
  Filter,
  SearchInput,
  Pagination,
  BooleanField, SelectArrayInput
} from 'react-admin'
import ChipArrayField from "../../components/chipArrayField"
import {required} from "../../validators"

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" placeholder="닉네임으로 검색" alwaysOn/>
    <SearchInput source="emailSearch" placeholder="이메일로 검색" alwaysOn/>
    <SelectArrayInput label="연령대" style={{width: '200px'}} source="birthRangeIds" validate={required} multiple={true} choices={[
      { id: 1, name: '19세 이하' },
      { id: 2, name: '20 - 24세' },
      { id: 3, name: '25 - 29세' },
      { id: 4, name: '30 - 34세' },
      { id: 5, name: '35 - 39세' },
      { id: 6, name: '40 - 44세' },
      { id: 7, name: '50세 이상' }
    ]} />
    <SelectArrayInput label="관심운동" style={{width: '200px'}} source="exerciseIds" validate={required} multiple={true} choices={[
      { id: 1, name: '기초체력증진' },
      { id: 2, name: '근력강화' },
      { id: 3, name: '체형 교정·밸런스' },
      { id: 4, name: '스트레칭' },
      { id: 5, name: '칼로리버닝' },
      { id: 6, name: '혈액순환' },
      { id: 7, name: '힐링' }
    ]} />
    <SelectArrayInput label="관리부위" style={{width: '200px'}} source="musclePartIds" validate={required} multiple={true} choices={[
      { id: 1, name: '전신' },
      { id: 2, name: '상체' },
      { id: 3, name: '하체' },
      { id: 4, name: '코어·복부·척추' },
      { id: 5, name: '허리·골반·등' },
      { id: 6, name: '목·팔·어깨' },
      { id: 7, name: '엉덩이·허벅지' }
    ]} />
  </Filter>
)

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const UsersList = withStyles(styles)(({classes, ...props}) => {
  return (
    <>
      <List {...props}
            filters={<ListFilter/>}
            pagination={<PostPagination/>}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={50}
            exporter={false}
            bulkActionButtons={false}
            classes={{main: classes.main}}
      >
        <Datagrid rowClick="show" classes={{ row: classes.row }}>
          <NumberField source="id" sortable={false}/>
          <TextField source="nickname" sortable={false}/>
          <TextField source="email" sortable={false}/>
          <TextField source="type" />
          <TextField source="birthRange" sortable={false}/>
          <ChipArrayField {...props} type="musclePartIds" label="관리부위" sortable={false}/>
          <ChipArrayField {...props} type="exerciseIds" label="관심운동" sortable={false}/>
          <TextField source="membershipConvertKo"/>
          <DateField source="createdAt" locales='ko'/>
          <BooleanField source="isDeleted"/>
        </Datagrid>
      </List>
    </>
  )
})

export default UsersList
