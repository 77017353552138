import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin'
import {EditAction} from '../../components/actions'
import {EditToolbar} from '../../components/toolbars'
import {required} from "../../validators"
import RichTextInput from "ra-input-rich-text"

const FaqEdit = (props) => {
  return (
    <Edit {...props} actions={<EditAction/>}>
      <SimpleForm toolbar={<EditToolbar/>}>
        <TextInput source="title" validate={required}/>
        <RichTextInput source="contents" validate={required}/>
        <TextInput source="sortOrder" validate={required}/>
      </SimpleForm>
    </Edit>
  )
}

export default FaqEdit
