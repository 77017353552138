import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  Datagrid,
  DateField,
  List,
  TextField,
  Pagination, ImageField
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

// const ListFilter = props => (
//   <Filter {...props}>
//     <SearchInput source="search" placeholder="닉네임으로 검색" alwaysOn/>
//   </Filter>
// )

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const UsersVideosList = withStyles(styles)(({classes, ...props}) => {
  return (
    <>
      <List {...props}
            pagination={<PostPagination/>}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={50}
            exporter={false}
            bulkActionButtons={false}
            classes={{main: classes.main}}
      >
        <Datagrid rowClick="show" classes={{ row: classes.row }}>
          <TextField source="id" sortable={false}/>
          <ImageField source="thumbnail" sortable={false}/>
          <TextField source="name" sortable={false}/>
          <TextField source="subName" sortable={false}/>
          <TextField source="nickname" sortable={false}/>
          {/*<TextField source="viewCount" />*/}
          {/*<TextField source="replayCount" />*/}
          {/*<TextField source="likeCount" />*/}
          {/*<BooleanField source="isSecret"/>*/}
          <DateField source="createdAt" locales='ko' showTime sortable={false}/>
        </Datagrid>
      </List>
    </>
  )
})

export default UsersVideosList
