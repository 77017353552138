import React from 'react'
import {
  DateTimeInput,
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin'
import {EditAction} from '../../components/actions'
import {EditToolbar} from '../../components/toolbars'
import {required} from "../../validators"

const PushEdit = (props) => {
  return (
    <Edit {...props} actions={<EditAction/>}>
      <SimpleForm toolbar={<EditToolbar/>}>
        <TextInput source="title" validate={required}/>
        <TextInput source="contents" multiline validate={required}/>
        <DateTimeInput source="reservedDate" validate={required}/>
      </SimpleForm>
    </Edit>
  )
}

export default PushEdit
