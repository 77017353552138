import NotificationsIcon from '@material-ui/icons/Notifications'
import NoticeList from './NoticeList'
import NoticeShow from './NoticeShow'
import NoticeEdit from './NoticeEdit'
import NoticeCreate from './NoticeCreate'

const Notices = {
  create: NoticeCreate,
  list: NoticeList,
  show: NoticeShow,
  edit: NoticeEdit,
  icon: NotificationsIcon,
}


export default Notices
