import GradeIcon from '@material-ui/icons/Grade';
import UsersVideosList from './UsersVideosList'

const Presenters = {
    list: UsersVideosList,
    icon: GradeIcon,
}


export default Presenters
