import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import UsersShow from './UsersShow'
import UsersList from './UsersList'
import UsersEdit from './UsersEdit'

const Presenters = {
    list: UsersList,
    show: UsersShow,
    edit: UsersEdit,
    icon: SupervisedUserCircleIcon,
}


export default Presenters
