import SendIcon from '@material-ui/icons/Send'
import PushList from './PushList'
import PushShow from './PushShow'
import PushEdit from './PushEdit'
import PushCreate from './PushCreate'

const Pushes = {
  create: PushCreate,
  list: PushList,
  show: PushShow,
  edit: PushEdit,
  icon: SendIcon,
}


export default Pushes
