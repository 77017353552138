import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin'
import {required} from '../../validators'
import CreateActions from '../../components/actions/CreateActions'
import CreateToolbar from '../../components/toolbars/CreateToolbar'

const AdminCreate = (props) => (
  <Create {...props} actions={<CreateActions/>}>
    <SimpleForm
      toolbar={<CreateToolbar/>}>
      <TextInput source="name" validate={required}/>
      <TextInput source="password"
                 type='password'
                 validate={required}/>
    </SimpleForm>
  </Create>
)

export default AdminCreate
