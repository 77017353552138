import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin'
import {EditAction} from '../../components/actions'
import {EditToolbar} from '../../components/toolbars'
import {required} from "../../validators"

const CategoryEdit = (props) => {
  return (
    <Edit {...props} actions={<EditAction/>}>
      <SimpleForm toolbar={<EditToolbar/>}>
        <TextInput source="name" validate={required}/>
        <TextInput source="sortOrder" validate={required}/>
      </SimpleForm>
    </Edit>
  )
}

export default CategoryEdit
