import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  List,
  TextField,
  BooleanField,
  ImageField, DateField
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const Actions = ({basePath,}) => (
  <TopToolbar>
    <CreateButton basePath={basePath}/>
  </TopToolbar>
)

const BannerList = withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          sort={{ field: 'id', order: 'DESC' }}
          actions={<Actions/>}
          perPage={25}
          bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <ImageField source="image" sortable={false}/>
        <BooleanField source="isActive" sortable={false}/>
        <DateField source="createdAt" locales='ko' showTime sortable={false}/>
      </Datagrid>
    </List>
  )
})

export default BannerList
