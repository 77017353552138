import React from 'react'
import {
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField
} from 'react-admin'
import {ShowAction} from '../../components/actions'


const FaqShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props}/>}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false}/>
        <TextField source="sortOrder" sortable={false}/>
        <TextField source="title" sortable={false}/>
        <RichTextField source="contents" sortable={false}/>
        <DateField source="createdAt" locales='ko' sortable={false}/>
      </SimpleShowLayout>
    </Show>
  )
}

export default FaqShow
