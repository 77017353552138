import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import TagList from './TagList'
import TagShow from './TagShow'
import TagEdit from './TagEdit'
import TagCreate from './TagCreate'

const Tags = {
  create: TagCreate,
  list: TagList,
  show: TagShow,
  edit: TagEdit,
  icon: LocalOfferIcon,
}


export default Tags
