import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import FaqList from './FaqList'
import FaqShow from './FaqShow'
import FaqEdit from './FaqEdit'
import FaqCreate from './FaqCreate'

const Notices = {
  create: FaqCreate,
  list: FaqList,
  show: FaqShow,
  edit: FaqEdit,
  icon: QuestionAnswerIcon,
}


export default Notices
