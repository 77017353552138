import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  BooleanField,
  DateField
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const QuestionList = withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props}
          sort={{ field: 'id', order: 'DESC' }}
          perPage={25}
          bulkActionButtons={false}
          exporter={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false}/>
        <TextField source="nickname" sortable={false}/>
        <TextField source="title" sortable={false}/>
        <TextField source="contents" sortable={false}/>
        <BooleanField source="status" sortable={false}/>
        <DateField source="createdAt" locales='ko' sortable={false}/>
      </Datagrid>
    </List>
  )
})

export default QuestionList
